/*!
 * Bracket Plus Skin - Dark
 * Author: ThemePixels (@themepixels)
 * URL: http://themepixels.me/bracketplus
**/
body {
  background-color: #141d28; }

.br-logo {
  background-color: #26354a; }
  @media (min-width: 992px) {
    .br-logo {
      border-right-color: rgba(255, 255, 255, 0.1); } }
  .br-logo > a {
    color: #fff; }
    .br-logo > a i {
      color: #17A2B8; }
    .br-logo > a span {
      color: #1CAF9A; }

.br-header {
  background-color: #26354a;
  box-shadow: none; }
  .br-header::before {
    display: none; }

.navicon-left {
  border-right-color: rgba(255, 255, 255, 0.1); }
  .navicon-left a {
    color: rgba(255, 255, 255, 0.65); }
    .navicon-left a:hover, .navicon-left a:focus {
      color: #fff; }

.navicon-right {
  border-left-color: rgba(255, 255, 255, 0.1); }
  .navicon-right a {
    color: rgba(255, 255, 255, 0.65); }
    .navicon-right a:hover, .navicon-right a:focus {
      color: #fff; }

.br-header-left .form-control {
  background-color: transparent;
  color: #fff; }

.br-header-left .input-group {
  border-right-color: rgba(255, 255, 255, 0.1); }

.br-header-left .input-group-btn .btn {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.65); }
  .br-header-left .input-group-btn .btn:focus, .br-header-left .input-group-btn .btn:active {
    color: #fff; }

.br-header-right a {
  color: rgba(255, 255, 255, 0.65); }
  .br-header-right a:hover, .br-header-right a:focus {
    color: #fff; }

.br-header-right .dropdown {
  border-width: 0; }
  .br-header-right .dropdown:last-child hr {
    border-color: rgba(255, 255, 255, 0.04); }

.br-header-right .dropdown-footer {
  border-top-color: rgba(255, 255, 255, 0.05); }

.dropdown-menu-label {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .dropdown-menu-label label {
    color: #17A2B8; }

.media-list-link.read {
  background-color: #1b2736; }

.media-list-link:hover, .media-list-link:focus {
  background-color: #1a2432; }

.media-list-link .media-body > div p {
  color: #fff; }

.media-list-link .media-body > div span {
  color: #6c757d; }

.media-list-link .media-body p {
  color: #6c757d; }

.media-list-link .noti-text {
  color: rgba(255, 255, 255, 0.65); }
  .media-list-link .noti-text strong {
    color: #fff; }

.media-list-link + .media-list-link {
  margin-top: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.04); }

.nav-link-profile .square-10 {
  border-color: #26354a; }

.dropdown-menu-header {
  box-shadow: none;
  background-color: #1D2939; }
  .dropdown-menu-header::before {
    display: none; }

.logged-fullname {
  color: #fff; }
  .logged-fullname + p {
    color: rgba(255, 255, 255, 0.5); }

.profile-earning-label {
  color: #17A2B8; }

.profile-earning-amount {
  color: #fff;
  font-weight: 400; }

.profile-earning-text {
  color: rgba(255, 255, 255, 0.5); }

.user-profile-nav a:hover, .user-profile-nav a:focus {
  background-color: #1a2432; }

.user-profile-nav .icon {
  color: #17A2B8; }

/***** LEFT SIDEBAR *****/
.br-sideleft .sidebar-label:first-child {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px; }

@media (min-width: 992px) {
  .br-sideleft {
    border-right: 1px solid rgba(255, 255, 255, 0.08); } }

.br-subleft {
  background-color: #1b2736;
  border-right: 1px solid rgba(255, 255, 255, 0.1); }

/***** MAINPANEL *****/
.br-pageheader {
  background-color: #111821; }
  .br-pageheader .breadcrumb-item:not(.active) {
    color: rgba(255, 255, 255, 0.5); }
    .br-pageheader .breadcrumb-item:not(.active):hover, .br-pageheader .breadcrumb-item:not(.active):focus {
      color: #fff; }
  .br-pageheader .breadcrumb-item.active {
    color: rgba(255, 255, 255, 0.85); }

.br-pagetitle .icon {
  color: #17A2B8; }

.br-pagetitle h4 {
  color: #fff; }

.br-section-wrapper {
  background-color: #1D2939;
  border: 1px solid rgba(255, 255, 255, 0.08);
  box-shadow: none; }

.br-section-label {
  color: #fff;
  font-weight: 500; }

.hljs {
  background-color: #1a2432;
  border: 1px solid rgba(255, 255, 255, 0.08); }

.table-dark-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.08); }
  .table-dark-wrapper .table {
    font-size: 13px;
    margin-bottom: 0; }
    .table-dark-wrapper .table thead tr th {
      background-color: #1a2432;
      color: #fff;
      border-color: rgba(255, 255, 255, 0.08);
      font-weight: 500; }
    .table-dark-wrapper .table tbody tr td {
      background-color: #1D2939;
      color: #adb5bd;
      border-color: rgba(255, 255, 255, 0.08); }

/***** ACCORDION *****/
.accordion .card {
  background-color: #1a2432;
  color: #adb5bd;
  border-color: #223043; }

.accordion .card-header {
  font-weight: 500;
  font-size: 15px; }
  .accordion .card-header a {
    color: #fff; }
    .accordion .card-header a.collapsed {
      color: #adb5bd; }
      .accordion .card-header a.collapsed:hover, .accordion .card-header a.collapsed:focus {
        background-color: #18222f; }

/***** CARDS ******/
.card {
  background-color: #1a2432;
  border-color: rgba(255, 255, 255, 0.1); }

.card-header,
.card-footer {
  border-color: rgba(255, 255, 255, 0.08); }

.card-title {
  font-weight: 500; }

.card-header-tabs .nav-link.active {
  background-color: #1a2432;
  border-color: rgba(255, 255, 255, 0.08);
  border-bottom-color: #1a2432;
  color: #fff; }

.card-header-tabs.nav-tabs-for-dark .nav-link.active {
  border-color: transparent; }

/***** CARD DASHBOARD *****/
@media (min-width: 576px) {
  .widget-1 > div:nth-child(2) .card {
    border-left-color: rgba(255, 255, 255, 0.08); }
  .widget-1 > div:last-child .card {
    border-left-color: rgba(255, 255, 255, 0.08); } }

@media (min-width: 992px) {
  .widget-1 > div:nth-child(3) .card {
    border-left-color: rgba(255, 255, 255, 0.08); } }

.widget-1 .card-title {
  font-weight: 500;
  color: #fff; }

.widget-1 .card-body span:last-child {
  color: #fff; }

.widget-1 .card-footer {
  border-color: rgba(255, 255, 255, 0.08); }

.widget-2 .card-header {
  border-bottom-color: rgba(255, 255, 255, 0.08); }
  .widget-2 .card-header .btn-group .btn {
    background-color: #1D2939;
    color: #adb5bd; }
    .widget-2 .card-header .btn-group .btn:hover, .widget-2 .card-header .btn-group .btn:focus {
      background-color: #141d28; }
    .widget-2 .card-header .btn-group .btn.active {
      background-color: #141d28; }

.widget-2 .card-title {
  font-weight: 500;
  color: #fff; }

.widget-4 .card-header {
  border-color: rgba(255, 255, 255, 0.1); }

.widget-4 .card-body {
  background-color: #1a2432; }

.widget-4 .card-footer {
  border-color: rgba(255, 255, 255, 0.1); }

.widget-5 .card-header span {
  color: #fff; }

.widget-5 .list-group-item {
  background-color: transparent;
  color: #868ba1;
  border-color: rgba(255, 255, 255, 0.05); }
  .widget-5 .list-group-item .msg-top span:first-child {
    color: #fff; }
  .widget-5 .list-group-item:hover, .widget-5 .list-group-item:focus {
    background-color: #18222f; }

.widget-6 .card-header,
.widget-6 .card-footer {
  border-color: rgba(255, 255, 255, 0.1); }

.widget-6 .card-body {
  background-color: transparent; }

.widget-6 .chat-1 {
  background-color: #141d28; }

.widget-6 .chat-2 {
  background-color: #26354a; }

.widget-6 .card-footer .form-control {
  background-color: #141d28;
  border: 0;
  min-height: 40px;
  color: #fff; }

.widget-9 .list-group-item {
  background-color: transparent;
  border-color: #223043; }

.widget-10 .card-title {
  color: #fff; }

.widget-10 .list-group-item {
  background-color: transparent;
  border-color: #202e40;
  color: #868ba1; }
  .widget-10 .list-group-item:hover, .widget-10 .list-group-item:focus {
    background-color: #18222f; }

.widget-11 .card-header {
  border-color: rgba(255, 255, 255, 0.05); }

.widget-11 .card-title {
  color: #fff; }

.widget-11 .ckbox span {
  color: #868ba1; }

.widget-11 .card-footer {
  border-color: rgba(255, 255, 255, 0.05); }

.widget-12 .card-header {
  border-color: rgba(255, 255, 255, 0.05); }

.widget-12 .card-title {
  color: #fff; }

.widget-13 .card-title {
  color: #fff; }

.widget-13 .list-group-item {
  background-color: transparent;
  border-color: #202e40;
  color: #868ba1; }

.widget-14 .card-title,
.widget-15 .card-title {
  color: #fff; }

.widget-14 .form-control,
.widget-15 .form-control {
  min-height: 40px;
  background-color: #141d28;
  color: #fff; }

.widget-18 .wt-title {
  color: #fff; }

.widget-18 .input-group-text {
  background-color: #202e40;
  color: #868ba1;
  border-color: rgba(255, 255, 255, 0.1); }

.widget-18 .form-control {
  background-color: #141d28;
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  height: 40px; }

.widget-18 .btn {
  height: 40px;
  border: 0; }

.widget-18 .iconwrap {
  background-color: #202e40; }

/***** CODEMIRROR CODE EDITOR *****/
.codemirror-bracket-dark .CodeMirror {
  background-color: #1a2432 !important; }

.codemirror-bracket-dark .CodeMirror-gutters {
  background-color: #161f2b !important; }

.codemirror-bracket-dark .CodeMirror-simplescroll-vertical,
.codemirror-bracket-dark .CodeMirror-simplescroll-horizontal {
  background-color: #161f2b; }
  .codemirror-bracket-dark .CodeMirror-simplescroll-vertical div,
  .codemirror-bracket-dark .CodeMirror-simplescroll-horizontal div {
    background-color: #202e40; }

/***** COLOR PICKER *****/
.sp-replacer {
  background-color: #1a2432;
  border-color: rgba(255, 255, 255, 0.1);
  color: #868ba1; }
  .sp-replacer:hover, .sp-replacer:focus {
    color: #868ba1; }

/***** DATE PICKER *****/
.ui-datepicker {
  background-color: #1a2432;
  border-color: rgba(255, 255, 255, 0.1); }
  .ui-datepicker .ui-datepicker-calendar td {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.1); }
    .ui-datepicker .ui-datepicker-calendar td a {
      background-color: transparent; }
  .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff; }

/***** DROPDOWNS *****/
.dropdown-menu {
  background-color: #1a2432;
  box-shadow: none; }

/***** FLOT CHARTS *****/
.flot-overlay + .legend > div {
  background-color: transparent !important; }

.flot-overlay + .legend .legendLabel {
  color: #868ba1; }

/***** FORMS *****/
.form-control-dark {
  background-color: #1a2432;
  color: rgba(255, 255, 255, 0.65);
  border-color: rgba(255, 255, 255, 0.08); }
  .form-control-dark:active, .form-control-dark:focus {
    background-color: #18222f;
    color: #fff; }
  .form-control-dark[disabled], .form-control-dark[readonly] {
    background-color: #1D2939; }
  .form-control-dark.is-warning {
    border-color: #F49917; }
  .form-control-dark.bd-primary {
    border-color: #0866C6; }
  .form-control-dark.bd-success {
    border-color: #23BF08; }
  .form-control-dark.bd-warning {
    border-color: #F49917; }
  .form-control-dark.bd-danger {
    border-color: #DC3545; }
  .form-control-dark.bd-info {
    border-color: #17A2B8; }

.custom-file-label {
  background-color: #1a2432;
  border-color: rgba(255, 255, 255, 0.1); }

.input-group-dark .input-group-prepend > .input-group-text {
  border-right-color: transparent; }

.input-group-dark .input-group-append > .input-group-text {
  border-left-color: transparent; }

.input-group-dark .input-group-text {
  background-color: #1D2939;
  border-color: rgba(255, 255, 255, 0.08);
  color: #868ba1; }

.input-group-dark .form-control {
  background-color: #1a2432;
  border-color: rgba(255, 255, 255, 0.08); }

/***** FORM LAYOUTS *****/
.form-layout-1 {
  border-color: rgba(255, 255, 255, 0.1); }

.form-layout-2 .form-group,
.form-layout-3 .form-group {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: transparent; }

.form-layout-2 .form-group-active,
.form-layout-3 .form-group-active {
  background-color: #1a2432; }

.form-layout-2 .form-control,
.form-layout-3 .form-control {
  color: #fff;
  font-weight: 400; }

.form-layout-4,
.form-layout-5 {
  border-color: rgba(255, 255, 255, 0.1); }

.form-layout-6 .form-control,
.form-layout-7 .form-control {
  background-color: transparent;
  color: #fff; }

.form-layout-6 .row > div,
.form-layout-7 .row > div {
  border-color: rgba(255, 255, 255, 0.1); }
  .form-layout-6 .row > div:first-child,
  .form-layout-7 .row > div:first-child {
    background-color: #1b2736; }

/***** FORM VALIDATION *****/
.parsley-style-1 .parsley-input.parsley-error .form-control {
  background-color: #1a2432; }

.parsley-style-1 .parsley-checkbox.parsley-error {
  background-color: #1a2432; }

.parsley-style-1 .parsley-select.parsley-error .select2-container--default .select2-selection--single {
  background-color: #1a2432; }

/***** FORM WIZARDS *****/
.wizard > .steps .disabled a, .wizard > .steps .disabled a:hover, .wizard > .steps .disabled a:active {
  background-color: #1a2432;
  color: #868ba1; }

.wizard > .content {
  background-color: #1a2432; }
  .wizard > .content > .title {
    color: #fff; }
  .wizard > .content > .body input {
    border-color: rgba(255, 255, 255, 0.1); }

.wizard > .actions .disabled a, .wizard > .actions .disabled a:hover, .wizard > .actions .disabled a:active {
  background-color: #1a2432;
  color: #868ba1; }

.wizard-style-1 > .steps > ul a .number, .wizard-style-1 > .steps > ul a:hover .number, .wizard-style-1 > .steps > ul a:active .number,
.wizard-style-2 > .steps > ul a .number,
.wizard-style-2 > .steps > ul a:hover .number,
.wizard-style-2 > .steps > ul a:active .number,
.wizard-style-3 > .steps > ul a .number,
.wizard-style-3 > .steps > ul a:hover .number,
.wizard-style-3 > .steps > ul a:active .number {
  background-color: #141d28;
  color: #868ba1; }

.wizard-style-2 > .steps > ul a .number, .wizard-style-2 > .steps > ul a:hover .number, .wizard-style-2 > .steps > ul a:active .number {
  border-color: #1a2432; }

/***** INDEX PAGE *****/
.bd-white-1-force {
  border-color: rgba(255, 255, 255, 0.1) !important; }

/***** INPUT GROUP *****/
.input-group-addon {
  background-color: #141d28;
  color: #868ba1;
  border-color: rgba(255, 255, 255, 0.1); }

/***** LIST GROUP *****/
.list-group-item {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.06); }

.list-group-striped .list-group-item:nth-child(even) {
  background-color: #1b2736; }

/***** MAILBOX *****/
.br-mailbox-list {
  background-color: #1a2432;
  border-color: rgba(255, 255, 255, 0.1); }

.br-mailbox-list-header {
  border-color: rgba(255, 255, 255, 0.1); }

.br-mailbox-list-item {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.1); }
  .br-mailbox-list-item.active, .br-mailbox-list-item.active:hover, .br-mailbox-list-item.active:focus, .br-mailbox-list-item.unread, .br-mailbox-list-item.unread:hover, .br-mailbox-list-item.unread:focus, .br-mailbox-list-item:hover, .br-mailbox-list-item:focus {
    background-color: transparent; }
  .br-mailbox-list-item + .br-mailbox-list-item {
    border-color: rgba(255, 255, 255, 0.1); }

.br-msg-body {
  background-color: #1D2939;
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.1); }

.show-mailbox-left {
  color: #868ba1;
  border-color: rgba(255, 255, 255, 0.1); }
  .show-mailbox-left:hover, .show-mailbox-left:focus {
    color: #fff; }

/***** MEDIUM EDITOR PLUGIN *****/
.medium-editor-toolbar li button {
  background-color: #141d28; }

/***** MORRIS CHART *****/
.morris-dark text {
  fill: #868ba1; }

.morris-dark path {
  stroke: rgba(255, 255, 255, 0.1); }

/***** NAVIGATION *****/
.nav .nav-link {
  color: #868ba1; }
  .nav .nav-link:hover, .nav .nav-link:focus {
    color: #fff; }
  .nav .nav-link.active {
    color: #fff; }

.nav-colored-bg .nav-link {
  color: rgba(255, 255, 255, 0.65); }
  .nav-colored-bg .nav-link:hover, .nav-colored-bg .nav-link:focus {
    color: #fff; }
  .nav-colored-bg .nav-link.active {
    color: #fff; }

.nav-style-1 .nav-link {
  color: #868ba1; }
  .nav-style-1 .nav-link:hover, .nav-style-1 .nav-link:focus {
    background-color: #161f2b;
    color: #fff; }

.nav-style-2 .nav-link {
  color: #868ba1; }
  .nav-style-2 .nav-link + .nav-link {
    border-top: 1px solid #202e40; }
  .nav-style-2 .nav-link:hover, .nav-style-2 .nav-link:focus {
    color: #fff; }
  .nav-style-2 .nav-link.active, .nav-style-2 .nav-link.active:hover, .nav-style-2 .nav-link.active:focus {
    color: #fff; }

.nav-style-2 i {
  color: #868ba1; }

.nav-tabs-style-1 {
  background-color: #161f2b; }
  .nav-tabs-style-1 .nav-link {
    color: #6c757d; }
    .nav-tabs-style-1 .nav-link:hover, .nav-tabs-style-1 .nav-link:focus {
      background-color: #141d28; }
    .nav-tabs-style-1 .nav-link.active {
      background-color: #1a2432;
      color: #fff; }

.nav-effect-6 .nav-link {
  color: #fff; }

/***** PAGINATION *****/
.pagination-basic .page-item a.page-link {
  background-color: #1a2432;
  color: #868ba1; }
  .pagination-basic .page-item a.page-link:hover, .pagination-basic .page-item a.page-link:focus {
    background-color: #161f2b; }

.pagination-basic .page-item.active .page-link, .pagination-basic .page-item.active .page-link:hover, .pagination-basic .page-item.active .page-link:focus {
  background-color: #17A2B8;
  color: #fff; }

.pagination-circle .page-item .page-link,
.pagination-rounded .page-item .page-link {
  background-color: transparent;
  color: #868ba1;
  border-color: rgba(255, 255, 255, 0.1); }

/***** PROFILE PAGE *****/
.br-profile-page .card-header {
  background-color: #1b2736;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05); }

.br-profile-body .media-list .media + .media {
  border-top-color: rgba(255, 255, 255, 0.1); }

/***** PROGRESS BAR *****/
.progress {
  background-color: #141d28; }

/***** SELECT2 PLUGIN *****/
.select2-container--default .select2-selection--single {
  background-color: #1a2432;
  border-color: rgba(255, 255, 255, 0.08); }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #fff; }

.select2-container--default .select2-selection--multiple {
  background-color: #1a2432;
  border-color: rgba(255, 255, 255, 0.08); }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: rgba(255, 255, 255, 0.1); }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #1D2939; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #1D2939; }

.select2-container--default .select2-search--inline .select2-search__field::placeholder {
  color: #868ba1; }

/* #################### SELECT 2 SKINS #################### */
.select2-selection.select2-for-dark {
  border-color: rgba(52, 58, 64, 0.1); }

.select2-selection.select2-outline-primary {
  border-color: #0866C6;
  background-color: transparent; }
  .select2-selection.select2-outline-primary .select2-selection__rendered {
    color: #0866C6; }
  .select2-selection.select2-outline-primary .select2-selection__arrow b {
    border-top-color: #0866C6; }

.select2-selection.select2-outline-success {
  border-color: #23BF08;
  background-color: transparent; }
  .select2-selection.select2-outline-success .select2-selection__rendered {
    color: #23BF08; }
  .select2-selection.select2-outline-success .select2-selection__arrow b {
    border-top-color: #23BF08; }

.select2-selection.select2-outline-warning {
  border-color: #F49917;
  background-color: transparent; }
  .select2-selection.select2-outline-warning .select2-selection__rendered {
    color: #F49917; }
  .select2-selection.select2-outline-warning .select2-selection__arrow b {
    border-top-color: #F49917; }

.select2-selection.select2-outline-danger {
  border-color: #DC3545;
  background-color: transparent; }
  .select2-selection.select2-outline-danger .select2-selection__rendered {
    color: #DC3545; }
  .select2-selection.select2-outline-danger .select2-selection__arrow b {
    border-top-color: #DC3545; }

.select2-selection.select2-outline-info {
  border-color: #17A2B8;
  background-color: transparent; }
  .select2-selection.select2-outline-info .select2-selection__rendered {
    color: #17A2B8; }
  .select2-selection.select2-outline-info .select2-selection__arrow b {
    border-top-color: #17A2B8; }

.select2-selection.select2-outline-teal {
  border-color: #1CAF9A;
  background-color: transparent; }
  .select2-selection.select2-outline-teal .select2-selection__rendered {
    color: #1CAF9A; }
  .select2-selection.select2-outline-teal .select2-selection__arrow b {
    border-top-color: #1CAF9A; }

.select2-selection.select2-outline-indigo {
  border-color: #6610f2;
  background-color: transparent; }
  .select2-selection.select2-outline-indigo .select2-selection__rendered {
    color: #6610f2; }
  .select2-selection.select2-outline-indigo .select2-selection__arrow b {
    border-top-color: #6610f2; }

.select2-selection.select2-outline-purple {
  border-color: #6f42c1;
  background-color: transparent; }
  .select2-selection.select2-outline-purple .select2-selection__rendered {
    color: #6f42c1; }
  .select2-selection.select2-outline-purple .select2-selection__arrow b {
    border-top-color: #6f42c1; }

.select2-selection.select2-outline-pink {
  border-color: #e83e8c;
  background-color: transparent; }
  .select2-selection.select2-outline-pink .select2-selection__rendered {
    color: #e83e8c; }
  .select2-selection.select2-outline-pink .select2-selection__arrow b {
    border-top-color: #e83e8c; }

.select2-selection.select2-outline-orange {
  border-color: #f27510;
  background-color: transparent; }
  .select2-selection.select2-outline-orange .select2-selection__rendered {
    color: #f27510; }
  .select2-selection.select2-outline-orange .select2-selection__arrow b {
    border-top-color: #f27510; }

.select2-selection.select2-outline-white {
  border-color: rgba(255, 255, 255, 0.5);
  background-color: transparent; }
  .select2-selection.select2-outline-white .select2-selection__rendered {
    color: rgba(255, 255, 255, 0.5); }
  .select2-selection.select2-outline-white .select2-selection__arrow b {
    border-top-color: rgba(255, 255, 255, 0.5); }

.select2-selection.select2-full-color {
  border-color: transparent; }
  .select2-selection.select2-full-color .select2-selection__placeholder {
    color: rgba(255, 255, 255, 0.6); }
  .select2-selection.select2-full-color .select2-selection__rendered {
    color: #fff; }
  .select2-selection.select2-full-color .select2-selection__arrow b {
    border-top-color: rgba(255, 255, 255, 0.6); }

.select2-selection.select2-primary {
  background-color: #0866C6; }

.select2-selection.select2-success {
  background-color: #23BF08; }

.select2-selection.select2-warning {
  background-color: #F49917; }

.select2-selection.select2-danger {
  background-color: #DC3545; }

.select2-selection.select2-info {
  background-color: #17A2B8; }

.select2-selection.select2-indigo {
  background-color: #6610f2; }

.select2-selection.select2-purple {
  background-color: #6f42c1; }

.select2-selection.select2-pink {
  background-color: #e83e8c; }

.select2-selection.select2-teal {
  background-color: #1CAF9A; }

.select2-selection.select2-orange {
  background-color: #f27510; }

.select2-selection.select2-dark {
  background-color: #343a40; }

.select2-container--default.select2-container--open .select2-outline-primary .select2-selection__arrow b {
  border-bottom-color: #0866C6; }

.select2-container--default.select2-container--open .select2-outline-success .select2-selection__arrow b {
  border-bottom-color: #23BF08; }

.select2-container--default.select2-container--open .select2-outline-warning .select2-selection__arrow b {
  border-bottom-color: #F49917; }

.select2-container--default.select2-container--open .select2-outline-danger .select2-selection__arrow b {
  border-bottom-color: #DC3545; }

.select2-container--default.select2-container--open .select2-outline-info .select2-selection__arrow b {
  border-bottom-color: #17A2B8; }

.select2-container--default.select2-container--open .select2-outline-indigo .select2-selection__arrow b {
  border-bottom-color: #6610f2; }

.select2-container--default.select2-container--open .select2-outline-purple .select2-selection__arrow b {
  border-bottom-color: #6f42c1; }

.select2-container--default.select2-container--open .select2-outline-pink .select2-selection__arrow b {
  border-bottom-color: #e83e8c; }

.select2-container--default.select2-container--open .select2-outline-teal .select2-selection__arrow b {
  border-bottom-color: #1CAF9A; }

.select2-container--default.select2-container--open .select2-outline-orange .select2-selection__arrow b {
  border-bottom-color: #f27510; }

.select2-container--default.select2-container--open .select2-outline-white .select2-selection__arrow b {
  border-bottom-color: rgba(255, 255, 255, 0.5); }

.select2-container--default.select2-container--open .select2-full-color .select2-selection__arrow b {
  border-bottom-color: rgba(255, 255, 255, 0.6); }

.hover-success .select2-results__option--highlighted[aria-selected] {
  background-color: #23BF08; }

.hover-warning .select2-results__option--highlighted[aria-selected] {
  background-color: #F49917; }

.hover-danger .select2-results__option--highlighted[aria-selected] {
  background-color: #DC3545; }

.hover-info .select2-results__option--highlighted[aria-selected] {
  background-color: #17A2B8; }

.hover-indigo .select2-results__option--highlighted[aria-selected] {
  background-color: #6610f2; }

.hover-purple .select2-results__option--highlighted[aria-selected] {
  background-color: #6f42c1; }

.hover-orange .select2-results__option--highlighted[aria-selected] {
  background-color: #f27510; }

.hover-pink .select2-results__option--highlighted[aria-selected] {
  background-color: #e83e8c; }

.hover-teal .select2-results__option--highlighted[aria-selected] {
  background-color: #1CAF9A; }

.hover-dark .select2-results__option--highlighted[aria-selected] {
  background-color: #343a40; }

.select2-drop-color {
  border-color: transparent; }
  .select2-drop-color .select2-results__option {
    color: rgba(255, 255, 255, 0.6); }
  .select2-drop-color .select2-results__option--highlighted[aria-selected] {
    background-color: rgba(0, 0, 0, 0.2); }
  .select2-drop-color .select2-results__option[aria-selected="true"] {
    background-color: rgba(0, 0, 0, 0.08); }

.select2-drop-primary {
  background-color: #0866C6; }

.select2-drop-success {
  background-color: #23BF08; }

.select2-drop-warning {
  background-color: #F49917; }

.select2-drop-danger {
  background-color: #DC3545; }

.select2-drop-info {
  background-color: #17A2B8; }

.select2-drop-indigo {
  background-color: #6610f2; }

.select2-drop-purple {
  background-color: #6f42c1; }

.select2-drop-pink {
  background-color: #e83e8c; }

.select2-drop-teal {
  background-color: #1CAF9A; }

.select2-drop-orange {
  background-color: #f27510; }

.select2-drop-dark {
  background-color: #343a40; }

/***** SITEMAP *****/
.br-sitemap-section h6 a {
  color: #fff; }

/***** SPINKIT *****/
.sk-circle .sk-child::before,
.sk-folding-cube .sk-cube::before,
.sk-cube-grid .sk-cube {
  background-color: #fff; }

/***** RANGE SLIDER *****/
.irs-line-left,
.irs-line-mid,
.irs-line-right,
.irs-min,
.irs-max {
  background-color: #141d28; }

.irs-min,
.irs-max {
  color: #868ba1; }

/***** TABLES *****/
.table thead tr th,
.table thead tr td,
.table tbody tr th,
.table tbody tr td {
  border-color: rgba(255, 255, 255, 0.08); }

.table thead tr th,
.table thead tr td {
  color: #adb5bd;
  font-weight: 400; }

.table tbody tr th,
.table tbody tr td {
  color: #868ba1; }

> th thead tr > th,
> th thead tr > td,
.table-colored thead tr > th,
.table-colored thead tr > td {
  color: #fff; }

> th thead tr > td,
> th tbody tr > td,
.table-colored thead tr > td,
.table-colored tbody tr > td {
  border-color: rgba(255, 255, 255, 0.8); }

/***** TABLE DATATABLE *****/
table.dataTable {
  border-color: rgba(255, 255, 255, 0.1); }
  table.dataTable.no-footer {
    border-color: rgba(255, 255, 255, 0.1); }
  table.dataTable.row-border tbody th,
  table.dataTable.row-border tbody td, table.dataTable.display tbody th,
  table.dataTable.display tbody td {
    border-top-color: rgba(255, 255, 255, 0.1); }
  table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #18222f; }
  table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #1a2432; }
  table.dataTable.display tbody tr:hover > th,
  table.dataTable.display tbody tr:hover > td,
  table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.display tbody tr:focus > th,
  table.dataTable.display tbody tr:focus > td,
  table.dataTable.display tbody tr:focus > .sorting_1, table.dataTable.order-column.stripe tbody tr:hover > th,
  table.dataTable.order-column.stripe tbody tr:hover > td,
  table.dataTable.order-column.stripe tbody tr:hover > .sorting_1, table.dataTable.order-column.stripe tbody tr:focus > th,
  table.dataTable.order-column.stripe tbody tr:focus > td,
  table.dataTable.order-column.stripe tbody tr:focus > .sorting_1 {
    background-color: #161f2b; }
  table.dataTable tbody tr {
    background-color: #1D2939; }
    table.dataTable tbody tr:hover > th,
    table.dataTable tbody tr:hover > td, table.dataTable tbody tr:focus > th,
    table.dataTable tbody tr:focus > td {
      background-color: #161f2b; }

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background-color: #1a2432; }

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #868ba1; }

.dataTables_wrapper .dataTables_paginate .paginate_button {
  background-color: #1a2432;
  color: #868ba1 !important; }
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover, .dataTables_wrapper .dataTables_paginate .paginate_button:focus {
    background-color: #161f2b;
    color: #868ba1 !important; }
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:focus {
    background-color: #1a2432; }

.dataTables_filter input {
  background-color: #1a2432;
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
  .dataTables_filter input:focus {
    background-color: #18222f; }

/***** TAGS INPUT *****/
.bootstrap-tagsinput {
  background-color: #1a2432;
  border-color: rgba(255, 255, 255, 0.1); }

/***** TOGGLES *****/
.toggle-slide .toggle-off,
.toggle-modern .toggle-slide .toggle-off {
  background-color: #141d28; }

.toggle-light .toggle-on + .toggle-blob,
.toggle-modern .toggle-on + .toggle-blob {
  border-color: #141d28; }

/** DATA TABLE **/
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before {
  background-color: #16181b;
  color: rgba(255, 255, 255, 0.5); }

/** CHAT PAGE **/
.br-chatpanel-left {
  background-color: #202e40;
  border-right-color: rgba(255, 255, 255, 0.1); }
  .br-chatpanel-left .nav .nav-link {
    color: #868ba1; }

.br-img-user::after {
  box-shadow: 0 0 0 2px #202e40; }

.br-active-contacts {
  border-bottom-color: rgba(255, 255, 255, 0.1); }

.br-chatlist .msg-count {
  box-shadow: 0 0 0 2px #202e40; }

.br-chatlist .media {
  border-color: rgba(255, 255, 255, 0.05); }
  .br-chatlist .media.new {
    background-color: #26354a; }
  .br-chatlist .media.selected {
    background-color: #1a2432; }
    .br-chatlist .media.selected .br-img-user::after {
      box-shadow: 0 0 0 2px #1a2432; }

.br-chatlist .media-contact-name span:first-child {
  color: #fff; }

.br-chatlist .media-contact-name span:last-child {
  opacity: .75; }

.br-chat-header {
  background-color: #202e40;
  border-bottom-color: rgba(255, 255, 255, 0.1); }
  .br-chat-header .chat-name h6 {
    color: #fff; }
  .br-chat-header .nav-link {
    color: #868ba1; }
    .br-chat-header .nav-link:hover, .br-chat-header .nav-link:focus {
      color: #fff; }

.br-chat-body .br-img-user::after {
  box-shadow: 0 0 0 2px #1D2939; }

.br-chat-footer {
  background-color: #202e40;
  border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .br-chat-footer .nav-link {
    color: #868ba1; }
    .br-chat-footer .nav-link:hover, .br-chat-footer .nav-link:focus {
      color: #fff; }
  .br-chat-footer .form-control {
    background-color: transparent;
    color: #fff; }
    .br-chat-footer .form-control:placeholder {
      color: #868ba1; }

/***** UTILITIES *****/
.bd {
  border-color: rgba(255, 255, 255, 0.1); }

.bd-t {
  border-top-color: rgba(255, 255, 255, 0.1); }

.bd-r {
  border-right-color: rgba(255, 255, 255, 0.1); }

.bd-b {
  border-bottom-color: rgba(255, 255, 255, 0.1); }

.bd-l {
  border-left-color: rgba(255, 255, 255, 0.1); }

/***** RTL SUPPORT *****/
.rtl .br-sideleft {
  border-left: 1px solid rgba(255, 255, 255, 0.1); }

.rtl .br-subleft {
  border-right: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.1); }

.rtl .br-mailbox-list {
  border-left-color: rgba(255, 255, 255, 0.1); }

.rtl .br-header-left .input-group {
  border-right-width: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.1); }

.rtl .navicon-left,
.rtl .br-logo {
  border-left-color: rgba(255, 255, 255, 0.1); }

.rtl .navicon-right {
  border-right-color: rgba(255, 255, 255, 0.1); }

.rtl .widget-1 > div:nth-child(2) .card,
.rtl .widget-1 > div:nth-child(3) .card,
.rtl .widget-1 > div:last-child .card {
  border-right-color: rgba(255, 255, 255, 0.1); }

.rtl .input-group .input-group-text,
.rtl .input-group .form-control {
  border-color: rgba(255, 255, 255, 0.1) !important; }

.rtl .br-chatpanel-left {
  border-left-color: rgba(255, 255, 255, 0.1); }
